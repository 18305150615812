import styled from "styled-components";

import { devices } from "./devices";

export const ContainerApp = styled.div`
  font-family: sans-serif;
  text-align: center;
`;

export const Section = styled.section`
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  margin: 1em;

  width: 100%;
  min-height: calc(100vh / 2);
  transition: 0.5s ease-in-out;

  @media ${devices.simpleSmall} {
    width: 80vw;
    /* max-width: 980px; */
  }
`;

export const Options = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;

  gap: 3vw;
  row-gap: 1em;

  @media ${devices.simpleTablet} {
    flex-direction: row;
    row-gap: 3vw;
  }
`;

export const Option = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc((100vh - 6vh) / 3);

  padding: 3em;
  border-radius: 0.5em;
  background-color: rgba(10, 10, 10, 0.85);
`;

export const LogoHeader = styled.div`
  padding: 3em 0;
  text-align: center;

  & img {
    height: auto;
    width: 15em;
  }
`;

export const VideoBackground = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  opacity: 0.175;
  filter: grayscale(0.375);
`;

export const LoadingScreen = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;

  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity 2s;
  opacity: 1;

  &.show {
    opacity: 1;
    transition-timing-function: ease-in;
  }

  &.hide {
    opacity: 0;
    transition-timing-function: ease-out;
  }

  & img {
    width: 10em;
    height: auto;
  }
`;

export const Terms = styled.div`
  margin: 0.5em 0 2em 0;
  text-align: left;
`;
