import Header from "../components/Header";
import Institutional from "../components/Institutional";

import { Container, Option, Options, Section } from "../assets/estilos";
import { H1, H2 } from "../assets/html";

import "../assets/colors.css";
import "../assets/fonts.css";

export default function WoOps() {
  return (
    <Section>
      <Container>
        <Header />

        <Options>
          <Option>
            <H1 className={"c00 CoutureBold"}>WoOps!</H1>
            <H2 className={"cFFF"}>
              A página que você busca não foi encontrada.
            </H2>
            {/* <P className={"cFFF"}>{item.content}</P> */}
          </Option>
        </Options>

        <Institutional />
      </Container>
    </Section>
  );
}
