import styled from "styled-components";

import Header from "../components/Header";
import Institutional from "../components/Institutional";

import { Container, Option, Options, Section } from "../assets/estilos";
import { H1, H2, P } from "../assets/html";

import "../assets/colors.css";
import "../assets/fonts.css";

const PThankYou = styled(P)`
  text-align: center;
`;

export default function ThankYou() {
  return (
    <Section>
      <Container>
        <Header />

        <Options>
          <Option>
            <H1 className={"c00 CoutureBold"}>Obrigado</H1>
            <H2 className={"cFFF"}>Suas informações foram enviadas</H2>
            <PThankYou className={"cFFF"}>
              Agradecemos o seu interesse pela EcoArts. Em breve entraremos em
              contato com você.
            </PThankYou>
          </Option>
        </Options>

        <Institutional />
      </Container>
    </Section>
  );
}
