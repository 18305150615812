import styled from "styled-components";

import { devices } from "./devices";

export const H1 = styled.h1`
  font-family: "Couture Bold";
  font-size: 2em;
  text-transform: uppercase;

  padding: 0;
  margin: 0;
`;

export const H2 = styled.h2`
  font-family: "Roboto", sans-serif;
  font-weight: 100;

  text-transform: uppercase;

  padding: 0;
  margin: 0 0 1.5em 0;
`;

export const P = styled.p`
  text-align: justify;
  line-height: 130%;

  font-size: 1.125em;
  font-weight: 100;

  margin-bottom: 2em;
`;

export const BUTTON = styled.button`
  width: 100%;
  background-image: linear-gradient(
    to right,
    rgb(155, 146, 119) 0%,
    rgb(204, 204, 175) 100%
  );

  padding: 0.75em 2em;
  cursor: pointer;

  font-size: 1em;
  font-weight: bolder;
  text-align: center;
  text-transform: uppercase;
  transition: 1s ease-in-out;

  border: none;
  box-shadow: 0;
  border-radius: 2em;

  margin-top: auto;

  &:hover {
    background-position: right left;
  }
`;

export const IMAGE = styled.img`
  width: 100%;
  height: auto;

  border-radius: 0.5em;
  margin: 0 0 2em 0;
`;

export const FORM = styled.form`
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  flex-shrink: 1;

  @media ${devices.simpleTablet} {
    width: 50%;
  }
`;

export const INPUT = styled.input`
  font-size: 1.25em;
  font-weight: 100;
  padding: 0.75em;
  margin-bottom: 0.75em;

  border: none;
  border-radius: 0.25em;
`;
