const sizes = {
  // mobileS: "320px",
  // mobileM: "375px",
  // mobileL: "425px",
  // tablet: "768px",
  // laptop: "1024px",
  // laptopL: "1440px",
  // desktop: "2560px",

  breakSmall: "576px",
  breakTablet: "768px",
  breakDesktop: "992px",
  breakLarge: "1200px"
};

export const devices = {
  // mobileS: `(min-width: ${sizes.mobileS})`,
  // mobileM: `(min-width: ${sizes.mobileM})`,
  // mobileL: `(min-width: ${sizes.mobileL})`,
  // tablet: `(min-width: ${sizes.tablet})`,
  // laptop: `(min-width: ${sizes.laptop})`,
  // laptopL: `(min-width: ${sizes.laptopL})`,
  // desktop: `(min-width: ${sizes.desktop})`,

  simpleSmall: `(min-width: ${sizes.breakSmall})`,
  simpleTablet: `(min-width: ${sizes.breakTablet})`,
  simpleDesktop: `(min-width: ${sizes.breakDesktop})`,
  simpleLarg: `(min-width: ${sizes.breakLarge})`
};
