import axios from "axios";
import { createForm } from "@use-form/use-form";
import { useHistory } from "react-router-dom";

import { Container, Option, Options, Section, Terms } from "../assets/estilos";
import { BUTTON, FORM, H1, H2, INPUT } from "../assets/html";
import Header from "../components/Header";
import Institutional from "../components/Institutional";

import "../assets/colors.css";
import "../assets/fonts.css";

const useForm = createForm({
  initialValues: {
    nome: "",
    email: "",
    mobile: "",
    accept: true,
  },
});

export default function WaitingList() {
  const { register, handleSubmit } = useForm();
  let history = useHistory();

  const onSubmit = (data) => {
    console.log(data);
  };

  let formSubmit = async (data) => {
    console.log(data);

    try {
      console.log("try");

      // let res = await fetch("https://ecoarts.org.br/mail.php", {
      //   method: "POST",
      //   // mode: "no-cors", // no-cors, cors, *same-origin *=default
      //   // credentials: "include", // *same-origin
      //   mode: "cors",
      //   withCredentials: false,
      //   crossDomain: true,
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "X-Requested-With": "XMLHttpRequest",
      //     "Content-Type": "application/json",
      //   },
      //   body: data, //JSON.stringify()
      // });

      axios
        .post("https://ecoarts.org.br/mail.php", data, {
          // mode: "no-cors",
          crossDomain: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          history.push("/thank-you");
        })
        .catch((err) => {
          console.log(err);
          history.push("/WoOps");
        });

      // // let resJson = await res.json();
      // if (res.status === 200) {
      //   history.push("/thank-you");
      // } else {
      //   history.push("/WoOps");
      // }
    } catch (err) {
      console.log(err);
      history.push("/WoOps?error=" + err);
    }
  };

  return (
    <Section>
      <Container>
        <Header />

        <Options>
          <Option>
            <H1 className={"c00 CoutureBold"}>Waiting List</H1>
            <H2 className={"cFFF"}>Preencha o formulário</H2>

            <FORM onSubmit={handleSubmit(formSubmit)}>
              <INPUT
                name="nome"
                type="text"
                label="Nome"
                placeholder="Nome"
                ref={register("nome")}
              />
              <INPUT
                name="email"
                type="email"
                label="E=Mail"
                placeholder="E-mail"
                ref={register("email")}
              />
              <INPUT
                name="mobile"
                type="tel"
                label="Celular"
                placeholder="Celular"
                ref={register("mobile")}
              />

              <Terms>
                <label className={"c00"}>
                  <input
                    type="checkbox"
                    name="accept"
                    ref={register("accept")}
                  />{" "}
                  Concordo com todos os termos da Política de Privacidade da
                  EcoArts.
                </label>
              </Terms>

              <BUTTON type="submit">Enviar</BUTTON>
            </FORM>
          </Option>
        </Options>

        <Institutional />
      </Container>
    </Section>
  );
}
