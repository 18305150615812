export const itensLanding = [
  {
    title: "Support",
    subtitle: "Eco Design",
    content:
      "As criações da Ecoarts contam histórias de sementes, árvores, rios, mitos e dos povos amazônicos, mostrando a dimensão imaterial da floresta. Parte das vendas da Ecoarts são revertidos no plantio de arvores, num modelo circular de preservação.",
    button: "Produtos & Collabs",
    link: "collabs",
  },
  {
    title: "Experience",
    subtitle: "Amazonia",
    content:
      "A experiencia na Amazonia profunda será de 1 a 12 de Setembro de 2022, numa viagem imersiva unindo ancestralidade, natureza, cultura e ecologia profunda. Deixe seu nome na lista de espera, entraremos em contato com você.",
    button: "Join Waiting List",
    link: "waiting-list",
  },
  {
    title: "Preserve",
    subtitle: "Plante, Adote e Doe",
    content:
      "Somos uma entidade sem fins lucrativos. É através dos recursos das vendas de nossos produtos e serviços, e de doações que investimos em ações de sustentabilidade e plantio de árvores. Seja um changemaker, veja como.",
    button: "Contribuir",
    link: "paypal",
  },
];

export const itensCollabs = [
  {
    title: "Le Lis Blanc",
    subtitle: "Coleção Amazônia",
    link: "https://www.lelis.com.br/lelisecoarts",
    image: "../jpg/le_lis_blanc.jpg",
  },
  {
    title: "Vista Alegre",
    subtitle: "Premiada na Europa",
    link: "https://vistaalegre.com/int/c/artistasdesigners-ecoarts-amazonia-int/",
    image: "../jpg/vista_alegre_00.jpg",
  },
  {
    title: "One Lady",
    subtitle: "Catálogo EcoArts",
    link: "https://www.onelady.com/us/ecoarts/ecoarts",
    image: "../jpg/catalogo_00.jpg",
  },
];
