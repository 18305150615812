import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import { ContainerApp, VideoBackground } from "./assets/estilos";
import GlobalStyle from "./assets/globals";
import LoadingScreen from "./components/LoadingScreen";
import Routes from "./Routes";

import "./assets/animation.css";
import "./assets/colors.css";

export default function App() {
  const [loading, setLoading] = useState(false);
  const [transitionStage, setTransistionStage] = useState("");

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setTransistionStage("fadeOut");
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    } else {
      return () => {};
    }
  }, [loading]);

  return (
    <BrowserRouter>
      <LoadingScreen transition={transitionStage} />

      <ContainerApp className={"b07"}>
        <VideoBackground
          autoPlay
          loop
          muted
          playsInline
          src="https://ecoarts.s3.amazonaws.com/landing/LandingBackground_Compressed.mp4"
          type="video/mp4"
          onLoadedData={() => {
            setLoading(true);
          }}
        />
        <Routes />
      </ContainerApp>
      <GlobalStyle />
    </BrowserRouter>
  );
}
