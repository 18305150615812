import { useHistory } from "react-router-dom";

import { Container, Option, Options, Section } from "../assets/estilos";
import { BUTTON, H1, H2, P } from "../assets/html";
import Header from "../components/Header";
import Institutional from "../components/Institutional";
import { itensLanding } from "../Data";

import "../assets/colors.css";
import "../assets/fonts.css";

export default function Landing() {
  let history = useHistory();

  function handleClick(where) {
    console.log("link to ", where);
    history.push(where);
  }

  return (
    <Section>
      <Container>
        <Header />

        <Options>
          {itensLanding.map((item, index) => (
            <Option key={index}>
              <H1 className={"c00 CoutureBold"}>{item.title}</H1>
              <H2 className={"cFFF"}>{item.subtitle}</H2>
              <P className={"cFFF"}>{item.content}</P>

              <BUTTON className={"c07"} onClick={() => handleClick(item.link)}>
                {item.button}
              </BUTTON>
            </Option>
          ))}
        </Options>

        <Institutional />
      </Container>
    </Section>
  );
}
