import { Link } from "react-router-dom";

import { LogoHeader } from "../assets/estilos";
import LogoEcoArts from "../assets/png/LogoEcoArts.png";

export default function Header() {
  return (
    <LogoHeader>
      <Link to={"/"} alt="Home">
        <img src={LogoEcoArts} alt="EcoArts Amazônia" />
      </Link>
    </LogoHeader>
  );
}
