import Header from "../components/Header";
import Institutional from "../components/Institutional";

import { Container, Option, Options, Section } from "../assets/estilos";
import { BUTTON, H1, H2, IMAGE } from "../assets/html";

import "../assets/colors.css";
import "../assets/fonts.css";

import { itensCollabs } from "../Data";

export default function Collabs() {
  function handleClick(where) {
    console.log("link to ", where);
    window.location = where;
  }

  return (
    <Section>
      <Container>
        <Header />

        <Options>
          {itensCollabs.map((item, index) => (
            <Option key={index}>
              <H1 className={"c00 CoutureBold"}>{item.title}</H1>
              <H2 className={"cFFF"}>{item.subtitle}</H2>

              <IMAGE src={`${item.image}`} alt={item.title} />
              <BUTTON className={"c07"} onClick={() => handleClick(item.link)}>
                Visitar
              </BUTTON>
            </Option>
          ))}
        </Options>

        <Institutional />
      </Container>
    </Section>
  );
}
