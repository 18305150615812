import Header from "../components/Header";
import Institutional from "../components/Institutional";

import { Container, Option, Options, Section } from "../assets/estilos";
import { H1, H2 } from "../assets/html";

import "../assets/colors.css";
import "../assets/fonts.css";

export default function Paypal() {
  const paypalURL =
    "https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=BSQXS7RG56BY4&source=url";
  window.location = paypalURL;

  return (
    <Section>
      <Container>
        <Header />

        <Options>
          <Option>
            <H1 className={"c00 CoutureBold"}>Paypal</H1>
            <H2 className={"cFFF"}>Redirecionando...</H2>
          </Option>
        </Options>

        <Institutional />
      </Container>
    </Section>
  );
}
