import { useState } from "react";

import { LoadingScreen as ScreenLoading } from "../assets/estilos";
import assetGoldenCircle from "../assets/png/GoldenCircle.png";

import "../assets/animation.css";
import "../assets/colors.css";

export default function LoadingScreen(props) {
  const [hide, setHide] = useState(false);

  return (
    <ScreenLoading
      style={{ display: hide ? "none" : "flex" }}
      className={props.transition + " b07"}
      onAnimationEnd={() => {
        setHide(true);
      }}
    >
      <img src={assetGoldenCircle} alt="Carregando" className="rotate" />
    </ScreenLoading>
  );
}
