import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Route, Switch, useLocation } from "react-router-dom";

const ga_trackingID = "G-ZWY70HNJM5";
ReactGA.initialize(ga_trackingID);

import Collabs from "./pages/Collabs";
import Landing from "./pages/Landing";
import Paypal from "./pages/Paypal";
import ThankYou from "./pages/ThankYou";
import WaitingList from "./pages/WaitingList";
import WoOps from "./pages/WoOps";

import "./assets/animation.css";

export default function Content() {
  let location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) {
      setTransistionStage("fadeOut");
    }

    ReactGA.pageview(window.location.pathname);
  }, [location]);

  return (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
    >
      <Switch location={displayLocation}>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route path="/waiting-list">
          <WaitingList />
        </Route>
        <Route path="/collabs">
          <Collabs />
        </Route>
        <Route path="/paypal">
          <Paypal />
        </Route>
        <Route path="/thank-you">
          <ThankYou />
        </Route>
        <Route path="*">
          <WoOps />
        </Route>
      </Switch>
    </div>
  );
}
